import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ServicesLayout from "../../components/services-layout"
import BookButton from "../../components/book-button"

const ImageContainer = styled.div`
  display: grid;
  justify-items: center;
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      a: file(
        relativePath: {
          eq: "services/care-plan-medicare-bulk-billed-podiatry-treatment-perth.jpeg"
        }
      ) {
        childImageSharp {
          fixed(height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Diabetic management" />
      <ServicesLayout title="Diabetic management">
        <h2>Diabetes and your feet - a summary</h2>
        <p>
          Diabetes is chronic disease that affects up to 6% of the population
          (higher in older age groups).
        </p>
        <p>
          Insulin is a hormone that helps the body deal with sugar (glucose) in
          the diet. When diabetes is present, either the body produces less or
          no insulin (Type 1) or the body tisues are resisant to the effects of
          insulin (Type 2). This results in higher levels of glucose in the
          blood, which can damage a whole range of body tissues and organs.
        </p>
        <p>
          In the feet, this damage causes poor circulation and loss of
          sensation.
        </p>
        <h2>Uncontrolled effects of diabetes on your feet</h2>
        <p>
          Without care the eventual result is skin ulcerations, infections, and
          finally amputation.
        </p>
        <h2>The podiatrists role in helping with foot care in diabetes</h2>
        <p>
          Podiatry visits are strongly recommended for monitoring, management
          and self-care advice. Your podiatrist can check areas of reduced
          circulation and sensation, prevent them from progressing to ulcers and
          collaborate with your GP to keep you out of hospital.
        </p>
        <h2>Medicare covered podiatry visits for diabetic patients</h2>
        <p>
          As a diabetic, you are eligible for a care plan from your GP which
          entitles you to up to 5 medicare funded visits to see us each calendar
          year. Our bulk-billing policy means that when you visit us with the
          care plan in hand, there is no out-of-pocket expense!
        </p>
        <ImageContainer>
          <Img
            fixed={data.a.childImageSharp.fixed}
            alt="Medicare Bulk Billed Podiatry Visit Perth"
          />
        </ImageContainer>
        <BookButton text="Book a bulk billed visit now" size="1.5rem" />
      </ServicesLayout>
    </Layout>
  )
}
